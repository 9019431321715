body {
  overflow-y: hidden;
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 20%;
}

.board {
  display: flex;
  height: 100vh;
}

.column {
  flex: 1;

  display: flex;
  flex-direction: column;

  height: 100vh;
  text-align: center;
  color:#000 !important;
  background-color:#c4c6c8 !important;
}

.columnCards {
  overflow-y: auto;
}

.card {
  flex: 1;
  color: #000 !important;
  background-color: #e1e2e4 !important;
  border-radius: 10px !important;
  border: 1px solid black !important;
  margin: 5px 0px;
}

.cardHeader {
  display: flex;
  padding: 5px;
}

.cardNumber {
  padding-right: 10px;
}

.cardRepository {
  font-weight: bold;
}

.cardAssignee {
  margin-left: auto;
}

.cardBody {
  padding: 0px 10px;
  text-align: left;
}

.cardFooter {
  display: flex;
  padding: 5px;
  box-shadow: 0px 5px 5px #888888;
}

.cardLabels {
  display: flex;
}

.cardURL {
  margin-left: auto;
}

.connectedPR {
  display: flex;
  border-top: 1px solid lightgrey;
}

.connectedPRNumber {
  padding: 0px 10px;
  font-weight: bold;
}

.connectedPRName {
  margin-left: 5px;
}

.connectedPRURL {
  margin-left: auto;
  padding: 0px 10px;
}
